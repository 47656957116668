
// css
require('../scss/main.scss');

// js
require('./forms.js');
require('./admin/main.js');
require('./plft/registrations.js');
require('./plft/appointments.js');
require('./spada/appointments.js');
require('./spada/present.js');
require('./guda/present.js');
require('./supervisor/supervisor.js');

$(() => {

  $('[data-toggle="tooltip"]').tooltip();

  // datetimepicker
  window.setDateTimePickers = () => {
    $.datetimepicker.setLocale('fr');
    $('input.datepicker').each(function() {
      $(this).attr('autocomplete', 'off');
      $(this).datetimepicker({
        timepicker: false,
        dayOfWeekStart: 1,
        format: 'd m Y',
        mask: true,
        scrollInput: false,
        minDate: $(this).data('min-date') || undefined,
        maxDate: $(this).data('max-date') || undefined,
      });
      // put caret in the beginning on click
      $(this).click(function() {
        if ($(this).val() !== '__ __ ____') {
          return;
        }
        $(this)[0].setSelectionRange(0, 0, 'backward');
      });
    });
    $('input.timepicker').datetimepicker({
      datepicker: false,
      format: 'H:i',
      step: 15,
      mask: true,
      scrollInput: false
    });
  }
  setDateTimePickers();

  // dataTables
  $('table.datatable').DataTable({
    language: {
      lengthMenu: 'Afficher _MENU_ lignes par page',
      zeroRecords: 'Aucun résultat',
      info: '_MAX_ ligne(s)',
      infoEmpty: 'Aucun résultat',
      infoFiltered: '(filtré sur _MAX_ lignes)',
      search: 'Filtre rapide',
      paginate: {
        first: 'Début',
        last: 'Fin',
        next: 'Suiv.',
        previous: 'Préc.'
      }
    },
    lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'Tout']],
    paging: false,
    order: [],
  });

});
