

$(function() {

  if (!$('select#regions-rules').length) {
    return;
  }

  //
  $('select#regions-rules').change(function() {
    const val = $(this).val();
    location.href = `/admin/regions/${val}/rules`;
  });

});
