
$(function() {

  if (!$('select.spada-list-present').length) {
    return;
  }
  let form;
  $('select.spada-list-present').change(function() {
    const val = $(this).val();
    form = $(this).parent('form');
    if (val !== '1') {
      return form.submit();
    }
    $('#date-modal').modal('show');
  });

  $('#modal-submit').click(function() {
    if (!form) {
      return ;
    }
    $('#start_at').clone().attr('hidden',true).appendTo(form);
    form.submit();
  });
});
