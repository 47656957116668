

$(() => {
  // reload supervisor page
  if (window.location.pathname !== '/supervisor') {
    return;
  }


  const reload = () => {
    const params = {
      date:       $('input#date').val(),
      region_id:  $('select#region').val(),
    };
    $.get('/supervisor/content', params, res => {
      $('#supervisor-content').html(res)
    });
  }

  reload();
  setInterval(reload, 3000);


});
