$(function() {

  if (!$('body.body-guda').length) {
    return;
  }

  $('select.list-present-select').change(function() {
    $(this).parent('form').submit();
  });

  $('a.btn-guda-allpresent').click(function() {
    return window.confirm($(this).data('confirm'));
  });
});
