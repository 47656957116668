
$(function() {

  if (!$('#timeslots-add').length) {
    return;
  }

  // add timeslot
  $('#timeslots-add').click(function() {

    const row = $('#timeslots-template').clone();
    row.removeClass('d-none');
    const idx = $('table.timeslots-table tbody tr').length;
    row.find('input').each(function() {
      ['name', 'id'].forEach(attr => {
        const val = $(this).attr(attr);
        val && $(this).attr(attr, val.replace('idx', idx));
      });
    });

    $('table.timeslots-table tbody tr').last().after(row.find('tr'));
    // reinit datetimepickers
    setDateTimePickers();

    return false;
  });

  // delete timeslot
  $('body').on('click', '.delete-timeslot', function() {
    const tr = $(this).parents('tr');
    if (tr.data('id')) {
      const input = $('input[name=delete]');
      input.val(input.val() + tr.data('id') + ' ');
    }
    tr.remove();
  });
});
