
$(function() {

  if (!$('#present-select')) {
    return;
  }

  $('#present-select').change(function() {
    if ($(this).val() === '1') {
      return $('.gu-appointment').show();
    }
    return $('.gu-appointment').hide();
  });
  $('#present-select').trigger('change');
});
