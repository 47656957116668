
$(function() {

  if (!$('input[name=phone]').length) {
    return;
  }

  // check phone
  $('input[name=phone]').change(function() {
    const params = {
      phone:  $(this).val(),
      id:     $(this).data('registration-id')
    };
    $.get('/plft/registrations/search', params, (data) => {
      $('#phone-alert').toggle(data && data.exists);
    });
  });

  // update required fields when housing situation changes
  const updateHousingSituation = () => {
    const val     = $('select#housing_situation').find('option:selected').val();
    const labels  = $('label[for=housing_type], label[for=housing_department_id]');
    labels.toggleClass('required', val !== 'none');
  }
  $('select#housing_situation').change(updateHousingSituation);
  updateHousingSituation();

});
