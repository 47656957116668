
$(function() {

  if (!$('#appointment-form')) {
    return;
  }

  $('#appointment-date, #appointment-region').change(function() {
    $('#appointment-form').submit();
  });

});
