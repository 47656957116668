
$(function() {

  if (!$('form#guda-form').length) {
    return;
  }

  // warning on changing status
  $('#guda-form').submit(function() {
    const old_val = $('#guda-status').data('value') ? 'disabled' : 'enabled';
    const new_val = $('#guda-disabled').is(':checked') ? 'disabled' : 'enabled';

    if (old_val === new_val || new_val === 'enabled') {
      return true;
    }
    return window.confirm($('#guda-status').data('confirm-' + new_val));
  });


});
