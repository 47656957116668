
$(function() {

  // Set select values
  $('select').each(function() {
    const v = $(this).data('value');
    if ($(this).attr('multiple') && typeof v === 'string') {
      $(this).val(v.split(',')).change() ;
    } else if (v === 0 || v) {
      $(this).val(v).change();
    }
  });

  // Init select2
  const sorter = function(results, e) {
    const searchfield = $('.select2-search__field:focus');
    if (!searchfield.length) {
      return results;
    }
    var query = searchfield.val().toLowerCase();
    if (!query) {
      return results;
    }
    return results.sort(function(a, b) {
      return a.text.toLowerCase().indexOf(query) -
        b.text.toLowerCase().indexOf(query);
    });
  }

  $('select.select2').select2({
    minimumResultsForSearch: 8,
    sorter:   data => data.sort((a, b) => a.text.localeCompare(b.text)),
    language: { noResults: () => 'Aucun résultat' },
    sorter
  });


  // Set radio values
  $('.form-group.form-radio').each(function() {
    const v = $(this).data('value');
    $(this).find('input[type=radio]').each(function() {
      $(this).attr('checked', $(this).val() === v);
    });
  });

  // Set checkboxes
  $('input[type=checkbox]').each(function() {
    const v = $(this).data('value');
    if (!v) {
      return;
    }
    $(this).attr('checked', !!v);
  });

});
