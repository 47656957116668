
$(function() {

  if (!$('select#role').length) {
    return;
  }

  // Enable/disable inputs depending on role select
  const usersInputs = [
    { id: 'spada-select',        role: 'spada'},
    { id: 'regions-select',      role: 'plft'},
    { id: 'guda-select',         role: 'guda'},
  ];

  $('select#role').change(function() {
    const val = $(this).val();
    usersInputs.forEach(input => {
      $('#' + input.id).prop('hidden', input.role !== val);
    });
  });
  $('select#role').trigger('change');

  // warning on changing status
  $('#user-update-form').submit(function() {
    const old_val = $('#user-status').data('value') ? 'disabled' : 'enabled';
    const new_val = $('#user-disabled').is(':checked') ? 'disabled' : 'enabled';

    if (old_val === new_val) {
      return true;
    }
    return window.confirm($('#user-status').data('confirm-' + new_val));
  });


});
